<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h3>Available in:</h3>
        <multi-select v-model="territoryInclude" :options="this.defs['Rights.Territory'].Options" />
      </b-col>

      <b-col>
        <h3>Excluded territories:</h3>
        <multi-select v-model="territoryExclude" :options="this.defs['Rights.Territory'].Options.filter(opt => opt.JsonValue !== 'WRD' )" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-alert :show="showValidationErrors.value" class="mt-4" variant="warning" v-for="(error, index) in errors" :key="index">{{ error }}</b-alert>
      </b-col>
    </b-row>
  </b-container>

</template>

<script>
  export default {
    name: 'ReleaseConceptTerritory',
    components: {
      'multi-select': () => import('@/components/MultiSelectList')
    },
    data () {
      return {
        disableUpdate: false,
        territoryExclude: [],
        territoryInclude: [],
      }
    },
    inject: {
      showValidationErrors: { default: { value: true } }
    },
    props: {
      value: String,
      defs: Object,
    },
    methods: {
      update() {
        if (this.value != this.territory) {
          this.$emit('input', this.territory)
        }
      },
      loadTerritory () {
        this.territoryInclude = []
        this.territoryExclude = []

        if (this.value == '') return
        let parts = this.value.split(' ')
        
        parts.forEach(p => {
          let option = p.substring(1)
          option = this.options[option]
          if (!option) return

          if (p.charAt(0) == '+') {
            this.territoryInclude.push(option)
          } else {
            this.territoryExclude.push(option)
          }
        })
      }
    },
    watch: {
      value: {
        handler () {
          if (this.value != this.territory) {
            this.loadTerritory()
          }
        },
        deep: true
      },
      territoryExclude () { this.update() },
      territoryInclude () { this.update() },
    },
    computed: {
      options () {
        let options = {}
        this.defs['Rights.Territory'].Options.forEach(o => {
          options[o.JsonValue] = o
        })
        return options
      },
      territory () {
        let territory = this.territoryInclude.map(item => '+'+item.JsonValue)
        territory = territory.concat(this.territoryExclude.map(item => '-'+item.JsonValue))
        return territory.join(' ')
      },
      errors () {
        let out = []
        if (this.hasOverlap) {
          out.push(this.$t('Territories are not allowed to be in both lists'))
        }

        if (this.excludeWorld) {
          out.push(this.$t('Excluding world is not allowed'))
        }

        if (this.territoryInclude.length == 0) {
          out.push(this.$t('At least one area has to be selected'))
        }
        
        this.$emit('errors', out)

        return out
      },
      hasOverlap () {
        let overlap = false
        this.territoryExclude.forEach(a => {
          if (this.territoryInclude.find(b => { return a.JsonValue == b.JsonValue }))
            overlap = true
        })
        return overlap
      },
      excludeWorld () {
        return this.territoryExclude.findIndex(i => { return i.JsonValue == 'WRD' }) != -1
      }
    },
    mounted () {
      this.loadTerritory()
    }
  }
</script>

<style lang="scss">

</style>